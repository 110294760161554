import React from 'react';
import * as styles from './CaseStudyUsedServices.module.scss';
import {Card} from "../Card";
import classNames from 'classnames';
import {BlockTitle} from "../BlockTitle";
import {MarkdownContent} from "../MarkdownContent";
import {Typography} from "../Typography";
import {Service} from "../Service";

export function CaseStudyUsedServices({services, className, content, ...otherProps}) {
    return <Card className={classNames(className, styles.root)} {...otherProps}>
        <BlockTitle className={styles.title}>Services in this project</BlockTitle>
        <MarkdownContent content={content} component={Typography}/>
        <div className={styles.services}>
            {services.map(service => {
                return <Service icon={service.icon}
                                key={service.slug}
                                appearance={"small-distance"}
                                slug={service.slug}
                                link
                                name={service.name}>
                    {service.description?.description}
                </Service>
            })}
        </div>
    </Card>
}
