// extracted by mini-css-extract-plugin
export var breadcrumbs = "CaseStudyHero-module--breadcrumbs--281CI";
export var content = "CaseStudyHero-module--content--vACiy";
export var image = "CaseStudyHero-module--image--iHK+s";
export var imageColor = "CaseStudyHero-module--imageColor--QkH2i";
export var imageWrapper = "CaseStudyHero-module--imageWrapper--vmg4S";
export var metrics = "CaseStudyHero-module--metrics--HN82w";
export var root = "CaseStudyHero-module--root--p2nIP";
export var service = "CaseStudyHero-module--service--Pz9P4";
export var services = "CaseStudyHero-module--services--l+YbE";
export var title = "CaseStudyHero-module--title--AADO4";